<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：审批管理 > <b>理事会</b></span>
            <div class="flex-center" @click="backPage"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>
        </div>
        <div class="sub-approval-info">
            <span>理事会</span>
            <div class="sub-approval-container">
                <div class="input-item">
                    <div class="input-item-title">议题</div>
                    <el-input v-model="title" placeholder="请输入议题"></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">备注</div>
                    <el-input v-model="remark" :rows="5" placeholder="请输入备注" resize="none" type="textarea"></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">上传附件</div>
                    <div>
                        <div class=" flex flex-wrap">
                            <div class="image-container" v-for="(file, index) in files" :key="index" >
                                <el-image :src="file.filePath" class="image" :preview-src-list="files.map(item=>{return item.filePath})"></el-image>
                                <i class="del el-icon-delete" @click="closeFile(index)"></i>
                            </div>
                        </div>
                        <el-upload
                            :action="uploadPath"
                            :before-upload="beforeAvatarUpload"
                            :data="QiniuData"
                            :on-error="uploadError"
                            :on-success="uploadSuccess"
                            :show-file-list="false"
                            accept="image/*"
                            multiple
                        >
                            <el-button size="small" type="primary">点击上传文件</el-button>
                            <!--<div slot="tip" class="el-upload__tip">只能上传PDF文件</div>-->
                        </el-upload>
                    </div>
                </div>
                <approvalList :copyMembers="copyMembers" :isNoHave="!id"
                              :typeClassify="19" @setApproval="setApproval"
                              @setMember="setMember"></approvalList>
                <el-button :disabled="requestState" :loading="requestState" type="primary" @click="subApproval">提交
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import approvalList from "../../components/approvalList.vue"
import {genUpToken, qiniuaddr, uploadPath} from "../../tools/CryptoJS";
import {webUrl} from "../../tools";

export default {
    components: {
        ElButton,
        ElInput,
        approvalList
    },
    name: "subApproval",
    data() {
        return {
            id: '',
            edit: 0,
            sign_code: '',
            title: '',
            remark: '',
            shenpi: '',
            copy: '',
            QiniuData: {
                fname: "", //  文件名
                key: "", //文件名字处理
                token: "", //七牛云token
            },
            qiniuaddr: qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
            uploadPath: uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有
            files: [],
            approvalMembers: [],
            copyMembers: [],
            requestState: false
        }
    },
    created() {
        let token = genUpToken();
        this.QiniuData.token = token;
        this.id = this.$route.query.id ? this.$route.query.id : ''
        this.edit = this.$route.query.edit ? this.$route.query.edit : 0
        this.$emit('setIndex', [5, 4])
        this.init()
    },
    methods: {
        subApproval() {
            if (!this.title) {
                this.$message.error("请输入议题")
                return
            }
            if (this.files.length === 0) {
                this.$message.error("请上传附件")
                return
            }
            this.requestState = true
            //添加
            if (this.edit) {
                this.$api.apiContent.editCouncil({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.id,
                    title: this.title,
                    files: JSON.stringify(this.files),
                    remark: this.remark,
                    shenpi: this.shenpi,
                    copy: this.copy,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        title: this.title,
                        files: JSON.stringify(this.files),
                        remark: this.remark,
                        shenpi: this.shenpi,
                        copy: this.copy,
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.requestState = false
                    this.$message.success(res.msg)
                    this.$router.push('send')
                }).catch(e => {
                    this.requestState = false
                    this.$message.error(e.msg)
                })
            } else {
                this.$api.apiContent.addCouncil({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign_code: this.sign_code,
                    title: this.title,
                    files: JSON.stringify(this.files),
                    remark: this.remark,
                    shenpi: this.shenpi,
                    copy: this.copy,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign_code: this.sign_code,
                        title: this.title,
                        files: JSON.stringify(this.files),
                        remark: this.remark,
                        shenpi: this.shenpi,
                        copy: this.copy,
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.requestState = false
                    this.$message.success(res.msg)
                    this.$router.push('/home/send?t='+Date.now())
                }).catch(e => {
                    this.requestState = false
                    this.$message.error(e.msg)
                })
            }

        },
        //审批人
        setApproval(members) {
            this.shenpi = members
        },
        //抄送人
        setMember(members) {
            this.copy = members
        },
        //  页面加载时请求
        init() {
            if (this.id) {
                this.$api.apiContent.getCouncil({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.id,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.files = res.data.files
                    this.sign_code = res.data.sign_code
                    this.title = res.data.title
                    this.remark = res.data.remark
                }).catch(() => {
                })
            }
        },
        //  上传成功
        uploadSuccess(response, file, fileList) {
            console.log(file);
            console.log(fileList);
            this.files.push({
                filePath: `${this.qiniuaddr}/${response.key}`,
                //在这里你就可以获取到上传到七牛的外链URL了
                fileName: file.name,
            });

            this.loading.close();
        },
        closeFile(position) {
            this.$confirm("此操作将删除该文件, 是否继续?", "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(() => {
                    this.files.splice(position, 1);
                })
                .catch();
        },
        //  上传失败
        uploadError() {
            this.loading.close();
            this.$message({
                message: "上传出错，请重试！",
                type: "error",
                center: true,
            });
        },
        beforeAvatarUpload(file) {
            console.log(file);
            //                const isJPG = file.type === 'application/pdf';
            //                if (!isJPG) {
            //                    this.$message.error('只能上传PDF格式!');
            //                    return
            //                }
            //这里的key给加上了时间戳，目的是为了防止上传冲突
            this.QiniuData.fname = file.name;
            this.QiniuData.key = `${new Date().getTime()}${file.name}`;
            this.loading = this.$loading({
                lock: true,
                text: "上传中，请稍后",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        backPage() {
            this.$tools.goBack()
        },
    },
    filters: {
        filePath(url) {
          return webUrl(url)
        },
    },
}
</script>

<style lang="scss" scoped>
.page-box {
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }

        }
    }

    .sub-approval-info {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        padding: 40px 25px;
        min-height: 800px;

        span {
            color: #000000;
            font-size: 24px;
        }

        .sub-approval-container {
            width: 60%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .input-item {
                display: flex;
                margin-bottom: 20px;
                flex-direction: row;
                align-items: center;
                overflow: hidden;

                .input-item-title {
                    width: 20%;
                    text-align: center;
                    font-size: 16px;
                    margin-right: 20px;
                    color: #000000;
                    flex-shrink: 0;
                }


                .image-container {

                    &:not(:nth-child(4n+1)){
                        margin-left: 20px;
                    }
                    width: 150px;
                    height: 150px;
                    position: relative;
                    border: 1px solid #e1e1e1;
                    margin-bottom: 20px;

                    .image {
                        width: 100%;
                        height: 100%;
                    }

                    .del {
                        position: absolute;
                        right: 1px;
                        top: 1px;
                        background: #e4393c;
                        border-radius: 20px;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        color: #FFFFFF;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                .input-item-desc {
                    width: 20%;
                    align-self: flex-start;
                    margin-top: 10px;
                    margin-right: 20px;
                    text-align: center;
                    font-size: 16px;
                    color: #000000;
                    flex-shrink: 0;
                }

                .el-input, .el-select, .el-textarea, .el-radio-group, .el-date-editor, .el-upload {
                    flex-grow: 1;
                    resize: none;
                }
            }

            > .el-button {
                margin-top: 30px;
                align-self: center;
                width: 40%;
            }
        }
    }
}
</style>